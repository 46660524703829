"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.MODULE_ORDER = exports.MODULE_PRESENTATION = exports.MODULE_COMMUNICATION = exports.MODULE_MONITORING = exports.MODULE_PROSPECTING = exports.MODULE_ANALYZING = exports.MODULE_SAFETY = exports.QUICK_ACCESS = void 0;
exports.QUICK_ACCESS = 'Accès rapide';
exports.MODULE_SAFETY = 'Sécuriser';
exports.MODULE_ANALYZING = 'Analyser';
exports.MODULE_PROSPECTING = 'Prospecter';
exports.MODULE_MONITORING = 'Surveiller';
exports.MODULE_COMMUNICATION = 'Communiquer';
exports.MODULE_PRESENTATION = 'Rapports / Appels entrants';
exports.MODULE_ORDER = (_a = {},
    _a[exports.MODULE_SAFETY] = 1,
    _a[exports.MODULE_ANALYZING] = 2,
    _a[exports.MODULE_MONITORING] = 3,
    _a[exports.MODULE_COMMUNICATION] = 4,
    _a[exports.MODULE_PROSPECTING] = 5,
    _a[exports.MODULE_PRESENTATION] = 6,
    _a);
