"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROUTES = void 0;
exports.ROUTES = {
    HOME: '/',
    BLOCKED_ACCESS: '/blocked-access',
    LOGOUT: '/logout',
    // Setup Activity
    SETUP_VERIFY_COPROPRIETES: '/setup/verify-coproprietes',
    SETUP_ADD_ADDRESSES: '/setup/add-addresses',
    SETUP_ADD_COPROPRIETES: '/setup/add-coproprietes',
    SETUP_COPRO_AREA: '/setup/copro-area',
    SETUP_ADD_COLLABORATORS: '/setup/add-collaborators',
    SETUP_CONFIGURE_NOTIFICATIONS: '/setup/configure-notifications',
    SETUP_DIFF_IMPORTED_RNIC: '/setup/diff-imported-rnic/',
    SETUP_DIFF_IMPORTED_RNIC_FILE_ID: '/setup/diff-imported-rnic/:fileId',
    // Analyzing
    ANALYZING_MARKET_VISION: '/analyzing/market-vision',
    ANALYZING_DPE_VISION: '/analyzing/dpe-vision',
    // Prospecting
    PROSPECTING_NEW_OPPORTUNITIES: '/prospecting/new-opportunities',
    PROSPECTING_IDENTIFIED_OWNER: '/prospecting/identified-owner',
    PROSPECTING_COMMUNICATE: '/prospecting/communicate',
    PROSPECTING_TEAM_PERFORMANCE: '/prospecting/team-performance',
    PROSPECTING_SCIS: '/prospecting/scis',
    PROSPECTING_SCIS_SIREN: '/prospecting/scis/:siren',
    // Monitoring
    MONITORING_BAUX_COMMERCIAUX: '/monitoring/baux-commerciaux',
    MONITORING_BAUX_A_ECHEANCE: '/monitoring/baux-a-echeance',
    MONITORING_ENTREPRISES_EN_DEFAILLANCE: '/monitoring/entreprises-en-defaillance',
    // Quick Access
    QUICK_ACCESS_COPRO_MANAGING: '/quick-access/copro-managing',
    QUICK_ACCESS_COPRO_MANAGING_ID: '/quick-access/copro-managing/:coproId',
    QUICK_ACCESS_COPRO_VIGILANCE: '/quick-access/copro-vigilance',
    QUICK_ACCESS_GENERAL_ASSEMBLY: '/quick-access/general-assembly',
    QUICK_ACCESS_GESTIONNAIRES_COPROS: '/quick-access/gestionnaires-copros',
    // Communication
    COMMUNICATION_NEWSLETTER: '/communication/newsletter',
    COMMUNICATION_PAGE_SITE: '/communication/page-site',
    COMMUNICATION_SCRIPT_APPEL: '/communication/script-appel',
    // Reports and Calls
    REPORTS_GENERATE_COPROPRIETE: '/reports-and-calls/generate-copropriete-report',
    REPORTS_GENERATE_DPE: '/reports-and-calls/generate-dpe-report',
    REPORTS_GENERATE_SALES: '/reports-and-calls/generate-sales-report',
    // Admin
    ADMIN_IMPORT: '/admin/import',
    ADMIN_DIFF: '/admin/diff',
    ADMIN_COMPANY_VERIFICATION: '/admin/company-verification',
    ADMIN_COPRO_VALIDATION: '/admin/copro-validation/*',
    ADMIN_COMPANIES_LIST: '/admin/companies',
    ADMIN_COMPANY_DETAILS: '/admin/companies/:companyId',
};
