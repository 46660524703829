"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STEP_FINISH = exports.STEP_NOTIFICATION = exports.STEP_COLLABORATOR = exports.STEP_COPRO_AREA = exports.STEP_COPRO_ADD = exports.STEP_ADDRESS_VERIFICATION = exports.STEP_COPRO_VALIDATION = exports.STEP_INIT = void 0;
exports.STEP_INIT = 0;
exports.STEP_COPRO_VALIDATION = 1;
exports.STEP_ADDRESS_VERIFICATION = 2;
exports.STEP_COPRO_ADD = 3;
exports.STEP_COPRO_AREA = 4;
exports.STEP_COLLABORATOR = 5;
exports.STEP_NOTIFICATION = 6;
exports.STEP_FINISH = 7;
